<template>
  <div class="boxTr">
    <el-row class="dash-board">
      <el-col :span="24">
        <p class="in1">学员名称：</p>
        <el-input placeholder="输入姓名" v-model="stuName" style="width: 150px" class="e-inps"></el-input>
        <p class="in2">班级名称：</p>
        <!-- <el-input placeholder="输入班级" v-model="className" style="width: 200px" class="in3"></el-input> -->
        <el-select style="width: 190px" class="in3" placeholder="请选择班级" v-model="className" @change="classChange" filterable clearable>
          <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
        </el-select>
        <p class="in2" style="left: 550px;">课程内容：</p>
        <el-select style="width: 190px; left: 610px;" class="in3" placeholder="请选择课程内容  " v-model="courseId" filterable clearable>
          <el-option v-for="(item,index) in curriculumData"
                    :key="index"
                    :label="item.content"
                    :value="item.id"/>
        </el-select>
        <p class="in2" style="left: 810px;">状态：</p>
        <el-select style="width: 190px; left: 850px;" class="in3" placeholder="请选择状态" v-model="status" filterable clearable>
          <el-option v-for="(item,index) in statelist"
                    :key="index"
                    :label="item.value"
                    :value="item.id"/>
        </el-select>
        <div id="" class="select-btn-box">
          <el-button type="primary" @click="getOne" v-permission="['Train:select']" class="inquire">查询</el-button>
          <el-button type="warning" @click="reset" class="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          label="学员名称"
          align="center"
         >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级名称"
          align="center"
         >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="课程内容"
          align="center"
         >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.courseName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="讲师"
          align="center"
         >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.lecturer }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.status==0">待评估</span>
            <span v-if="scope.row.status==1">已评估</span>
          </template>
        </el-table-column>
        <el-table-column
          label="评估等级"
          align="center"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.level">{{ scope.row.level }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="评估时间"
          align="center"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{scope.row.createTime}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="success"  v-if="scope.row.status==1" v-permission="['Train:see']" @click="see(scope.row)">查 看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag">
      </el-pagination>
    </el-row>
    <el-dialog
      title="评估详情"
      :data="details"
      :visible.sync="centerDialogVisibleTwo"
      width="50%"
      v-if="details"
      center>
     <div>
       <el-form v-model="details" :inline="true" label-position="top">
         <el-row>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="教学态度" class="form-item">
               <p class="form-mes" >满分1分（分5档，每档0.2分）</p>
               <p class="form-grade">{{details.manner}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="理论深度及信息量" class="form-item" >
               <p class="form-mes">满分2分（分5档，每档0.4分）</p>
               <p class="form-grade">{{details.depthInfo}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="理论联系实际" class="form-item" >
               <p class="form-mes">满分2分（分5档，每档0.4分）</p>
               <p class="form-grade">{{details.actual}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="教学技巧及语言表达水平" class="form-item" >
               <p class="form-mes">满分2分（分5档，每档0.4分）</p>
               <p class="form-grade">{{details.skillExpress}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="教学互动及创新" class="form-item" >
               <p class="form-mes">满分1.5分（分5档，每档0.3分）</p>
               <p class="form-grade">{{details.interactInnovate}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="8" style="text-align: center;">
             <el-form-item label="教学整体效果" class="form-item" >
               <p class="form-mes">满分1.5分（分5档，每档0.3分）</p>
               <p class="form-grade">{{details.interactInnovate}} 分</p>
             </el-form-item>
           </el-col>
           <el-col :span="24" style="text-align: center;">
             <el-form-item label="总成绩" class="form-item" >
               <p class="form-mes">满分10分</p>
               <p class="form-grade">{{details.totalPoints}} 分</p>
             </el-form-item>
           </el-col>
         </el-row>
       </el-form>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisibleTwo = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAdminCourseList, getAdminCourseDetail } from '@/api/training.js'
  import { curriculumList, getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Train',
    data () {
      return {
        classData: [], // 存储班级列表信息
        curriculumData: [], // 存储课程列表信息
        tableData: [],
        stuName: '',
        className: '',
        status: '',
        courseId: '',
        statelist: [
          { id: 0, value: '待评估' },
          { id: 1, value: '已评估' }
        ],
        assessId: '',
        details: {},
        centerDialogVisibleTwo: false,
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      this.getOne()
      this.reset()
      this.findClass()
    },
    methods: {
      // 课程列表信息
       curriculum() {

        },
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      getOne () {
        const query = {
          classId: this.className,
          stuName: this.stuName,
          status: this.status,
          courseId: this.courseId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getAdminCourseList(query).then(resp => {
          if (resp.data.code === '0') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      see (row) {
        console.log(row)
        this.centerDialogVisibleTwo = true
        const id = row.id
        getAdminCourseDetail(id).then(resp => {
          this.details = resp.data.data
        })
      },
      reset() {
        this.className = ''
        this.stuName = ''
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      },
      classChange(e) {
        console.log(e)
        const query = {
          trainId: e,
          pageNum: 1,
          pageSize: 10
        }
        curriculumList(query).then(resp => {
          if (resp.data.code === '0') {
              this.total = resp.data.data.total // 查询总条数
              this.curriculumData = resp.data.data.rows // 接收返回的数据
           }
        })
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  /* .dash-board button{margin: 0px 15px;} */
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 270px}
  .in3{width: 160px; position: absolute; top: 14px;left: 340px}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 80px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-bg span{color: #000; font-weight: bold;}
  .row-text{line-height: 24px;}
  .select-btn-box{
    position: absolute;
    left: 1055px;
    top: 14px;
  }
  .form-mes{line-height: 12px; margin: 0; padding: 0 10px; font-size: 12px;font-weight: 100;color:#a66262}
  .form-grade{background-color: #FFFFFF;margin-bottom: 0;}
  .form-item{ width: 100%; background-color: #F5F7FA;border: 1px solid #F2F2F2;}
</style>
